

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #162A52;
  color: aliceblue;
}

.App {
  max-width: 85%;
  margin: 0 auto;
  padding: 20px;
}

.hero {
  text-align: center;
  padding: 50px 0;
  background-color: #122242;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3);
  margin-bottom: 20px;
  color: aliceblue;
}

.projects {
  margin-top: 20px;
}

.project-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 20px;
}

.project-card {
  background-color: #357DED;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.project-card img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.project-card {
  background-color: #357DED;
  padding: 20px;
  background-color: #122242;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3);
  text-align: center;
  overflow: hidden; /* Prevent overflow from large sketches */
  color: aliceblue;

}

.project-card canvas {
  width: 100% !important; /* Ensure the canvas scales */
  height: auto !important; /* Maintain aspect ratio */
  
}
.canvas-container {
  width: 100%; /* or a specific width */
  height: auto; /* or any desired height */
  display: flex; /* Optional: Center the canvas */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.sketch{


  align-self: center;
  margin: auto;
}

a{
  color: #357DED;
  text-decoration: none;
}

a:hover{
  color:aliceblue;
}

.button-link{
  color: #357DED;
  text-decoration: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.button-link:hover{
  color:aliceblue;
}


.project-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}



.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  display:flex;
  align-content: center;
  justify-content: center;
  margin: auto;
  color: aliceblue;
  
  border: none;
  outline: none;


}

.carousel img {
  background-color: transparent;
  border-radius: 5px;
  margin: 0 10px;
  align-self: center;
  margin: auto;

  border: none;
  outline: none;

}

.carousel .slick-slide img {
  border: none;
  outline: none;

}
.modal {
  background-color: #122242;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100vh;

}

.modal .sketch{
  width:50px;
}

.close-button {
  background-color: transparent;
  border: none;
  color: aliceblue;
  font-size: 40px;
  position: fixed;
  right: 100px;
  top: 100px;
  font-weight: bold;
}

.close-button:hover{
  cursor: pointer;
}

.slider {
  max-width: 90%;
  align-self: center;
  margin: auto;
  margin-bottom: 50px;
  color: aliceblue;
  

}

.link-alert{
  font-size: 12px;
  font-style: italic;
  color: lightgray;
}

.intro-line{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

}

.logo {
  width: 75px;
  border-radius: 50%;


}

/* Change default color of dots */
.slider .slick-dots li button:before {
  color:aliceblue;
}

/* Change color of active dot */
.slider .slick-dots .slick-active button:before {
  color: aliceblue;
}


@media (max-width: 600px) {
  .canvas-container {
      height: 300px; /* Adjust for smaller screens */
  }
}

@media (min-width: 601px) {
  .canvas-container {
      height: 400px; /* Adjust for larger screens */
  }
}

/* This media query applies to screens smaller than 768px */
@media (max-width: 1500px) {
  .temp-hide { /* Replace with the actual class of your links */
      display: none; /* Hides the link */
  }
  #temp-hide-text{
    display: none; /* Hides the link */
  }
}

/* This media query applies to screens smaller than 768px */
@media (max-width: 990px) {
  .temp-hide-tsp { /* Replace with the actual class of your links */
      display: none; /* Hides the link */
  }

  #temp-hide-tsp-text{
    display: none; /* Hides the link */
  }
}

@media (max-width: 400px) {
  .intro-line{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;

  }
}
  
  /* This media query applies to screens smaller than 768px */
@media (max-width: 850px) {
  #temp-hide-p5 { /* Replace with the actual class of your links */
      display: none; /* Hides the link */
  }

  #temp-hide-p5-text{
    display: none; /* Hides the link */
  }
}




/*


.slick-next:before {
    color: #FF5733; 
    background: none; 
}


.slick-prev:before,
.slick-next:before {
    font-size: 24px; 
}

.slick-prev {
    left: -40px; 
    background-color: transparent;
}

.slick-next {
    right: -40px; 
    background-color: transparent;
    color: #000;
}


.slick-prev:hover:before,
.slick-next:hover:before {
    color: #FF4500;
}

*/